import React from 'react'
import { Avatar, Space, Dropdown, Menu } from 'antd'
import Icon from '@/components/Icon'
import AlertsBar from '@/components/AlertBar'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    cursor: pointer;
    margin-left: auto;

    .ant-dropdown {
        min-width: 0 !important;
        margin-left: 4px;
    }
`

const FlagIcon = styled.span`
    width: 32px !important;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #efefef;
    vertical-align: middle;
`

const menu = (
    <Menu>
        <Menu.Item>
            <Link to="/logout">Logout</Link>
        </Menu.Item>
    </Menu>
)

const UserMenu = ({ socket, ...props }) => {
    const { name, avatar, flag, role } = useSelector((state) => state.user)

    return (
        <Container {...props}>
            {role === 'country' && <AlertsBar socket={socket} />}
            <Dropdown
                arrow
                placement="bottomRight"
                trigger={['click']}
                overlay={menu}
                getPopupContainer={(node) => node}
            >
                <Space>
                    Hello, <b>{name}</b>
                    {flag ? (
                        <FlagIcon className={`${flag} flag-icon-squared`} />
                    ) : (
                        <Avatar src={avatar} icon={<Icon icon={['far', 'user']} />} />
                    )}
                </Space>
            </Dropdown>
        </Container>
    )
}

export default UserMenu
