const trainings = [
    {
        key: 'courses',
        label: 'Courses',
        icon: 'graduation-cap',
        route: { params: { configName: 'courses' }, name: 'listing' },
        permissions: ['view courses', 'courses'],
    },
    {
        key: 'crm',
        label: 'CRM',
        icon: 'users',
        route: { params: { page: 'crm' }, name: 'dashboard' },
        permissions: ['view', 'crm'],
        children: [
            {
                key: 'crm-trainers',
                label: 'Trainers',
                icon: 'chalkboard-teacher',
                permissions: ['view coaches', 'coaches'],
                route: { params: { configName: 'crm-trainers' }, name: 'listing' },
            },
            {
                key: 'doctors',
                label: 'Doctors',
                icon: 'user-md',
                permissions: ['view customers', 'doctors'],
                route: { params: { configName: 'doctors' }, name: 'listing' },
            },
        ],
    },
    {
        key: 'training-bonus',
        label: 'Training Bonus',
        icon: 'globe-americas',
        route: { name: 'training-bonus' },
        permissions: ['view training bonuses', 'training-bonus'],
    },
    {
        key: 'surveys',
        label: 'Post-Training Surveys',
        icon: 'edit',
        permissions: ['role', 'manager'],
        route: { params: { configName: 'surveys' }, name: 'listing' },
    },
]

export default trainings
