const mdf = {
    key: 'mdf',
    label: 'MDF',
    icon: 'gift',
    route: { params: { page: 'mdf' }, name: 'dashboard' },
    children: [
        {
            key: 'mdf-material',
            label: 'Mdf Material',
            icon: 'list-alt',
            route: { params: { page: 'mdf-material' }, name: 'dashboard' },
            permissions: [
                ['role', 'manager'],
                ['view mdf materials', 'mdf-materials'],
            ],
            children: [
                {
                    key: 'mdf-materials',
                    label: 'Materials',
                    icon: 'list-alt',
                    route: { params: { configName: 'mdf-materials' }, name: 'listing' },
                },
                {
                    key: 'mdf-categories',
                    label: 'Categories',
                    icon: 'list-alt',
                    route: { params: { configName: 'mdf-categories' }, name: 'listing' },
                },
            ],
        },
        {
            key: 'mdf-services',
            label: 'Services',
            icon: 'list',
            permissions: [
                ['role', 'manager'],
                ['view mdf services', 'mdf-services'],
            ],
            route: { params: { configName: 'mdf-services' }, name: 'listing' },
        },
        {
            key: 'mdf-order',
            label: 'MDF Order',
            icon: 'gift',
            route: { params: { page: 'mdf-order' }, name: 'dashboard' },
            children: [
                {
                    key: 'mdf-annual-plan',
                    label: 'Annual plan',
                    icon: 'list-alt',
                    route: { name: 'mdf-annual-plan' },
                    permissions: ['view annual goals', 'annual-goals'],
                },
                {
                    key: 'mdf-sales-plan',
                    label: 'Sales',
                    icon: 'list-alt',
                    route: { name: 'mdf-sales-plan' },
                    permissions: ['view mdf sales', 'mdf-sales'],
                },
                {
                    key: 'mdf-requests',
                    label: 'MDF Requests',
                    icon: 'gift',
                    route: { name: 'tab-listing', params: { configName: 'mdf-requests' } },
                },
            ],
        },
        {
            key: 'mdf-report',
            label: 'MDF Report',
            icon: 'gift',
            permissions: ['role', 'manager'],
            route: { name: 'mdf-report' },
        },
    ],
}

export default mdf
