import React, { lazy } from 'react'
import Login from '@/pages/Auth/Login'
import Logout from '@/pages/Auth/Logout'
import { Redirect } from 'react-router-dom'

export const publicRoutes = [
    {
        name: 'login',
        path: '/login',
        component: Login,
        exact: true,
    },
    {
        name: 'logout',
        path: '/logout',
        component: Logout,
        exact: true,
    },
    {
        name: 'forgot-password',
        path: '/forgot-password',
        component: lazy(() => import('@/pages/Auth/ForgotPassword')),
        exact: true,
    },
    {
        name: 'reset-password',
        path: '/reset-password',
        component: lazy(() => import('@/pages/Auth/ResetPassword')),
        exact: true,
    },
]

export const privateRoutes = [
    {
        name: 'home',
        path: '/',
        component: () => <Redirect to="/dashboard" />,
        exact: true,
    },
    {
        name: 'impersonate',
        path: '/t/impersonation',
        component: lazy(() => import('@/pages/Listing/Impersonate')),
        exact: true,
    },
    {
        name: 'dashboard',
        path: '/dashboard/:page?',
        component: lazy(() => import('@/pages/Dashboard/Dashboard')),
        exact: true,
    },
    {
        name: 'listing',
        path: '/t/:configName',
        component: lazy(() => import('@/pages/Listing/Listing')),
        exact: true,
    },
    {
        name: 'param-listing',
        path: '/tp/:configName/:configId',
        component: lazy(() => import('@/pages/Listing/ListingWithParams')),
        exact: true,
    },
    {
        name: 'tab-listing',
        path: '/tt/:configName',
        component: lazy(() => import('@/pages/Listing/ListingWithTabs')),
        exact: true,
    },
    {
        name: 'create',
        path: '/new/:configName',
        component: lazy(() => import('@/pages/Modify/Modify')),
        exact: true,
    },
    {
        name: 'update',
        path: '/edit/:configName/:id',
        component: lazy(() => import('@/pages/Modify/Modify')),
        exact: true,
    },
    {
        name: 'details',
        path: '/details/:configName/:id',
        component: lazy(() => import('@/pages/Details/Details')),
        exact: true,
    },
    {
        name: 'settings',
        path: '/settings/:configName/',
        component: lazy(() => import('@/pages/Settings')),
        exact: true,
    },
    {
        name: 'file-storage',
        path: '/file-storage',
        component: lazy(() => import('@/pages/Marketing')),
        exact: true,
    },
    {
        name: 'thread',
        path: '/thread/:id',
        component: lazy(() => import('@/pages/Thread')),
        exact: true,
    },
    {
        name: 'file-preview',
        path: '/file-preview/:id',
        component: lazy(() => import('@/pages/FilePreview')),
        exact: true,
    },
    {
        name: 'mdf-annual-plan',
        path: '/mdf-annual-plan',
        component: lazy(() => import('@/pages/MdfAnnualPlan')),
        exact: true,
    },
    {
        name: 'mdf-sales-plan',
        path: '/mdf-sales-plan',
        component: lazy(() => import('@/pages/MdfSalesPlan')),
        exact: true,
    },
    {
        name: 'mdf-report',
        path: '/mdf-report',
        component: lazy(() => import('@/pages/MdfReport')),
        exact: true,
    },
    {
        name: 'training-bonus',
        path: '/training-bonus',
        component: lazy(() => import('@/pages/TrainingBonus')),
        exact: true,
    },
    {
        name: 'create-course',
        path: '/new-course',
        component: lazy(() => import('@/pages/CourseModify')),
        exact: true,
    },
    {
        name: 'modify-course',
        path: '/edit-course/:id',
        component: lazy(() => import('@/pages/CourseModify')),
        exact: true,
    },
    {
        name: 'general-news',
        path: '/general-news',
        component: lazy(() => import('@/pages/News/GeneralNews')),
        exact: true,
    },
    {
        name: 'general-news-article',
        path: '/general-news-article/:id',
        component: lazy(() => import('@/pages/News/GeneralNewsArticle')),
        exact: true,
    },
    {
        name: 'internal-news',
        path: '/internal-news',
        component: lazy(() => import('@/pages/News/InternalNews')),
        exact: true,
    },
    {
        name: 'internal-news-article',
        path: '/internal-news-article/:id',
        component: lazy(() => import('@/pages/News/InternalNewsArticle')),
        exact: true,
    },
    {
        name: 'trainer-cabinet',
        path: '/trainer-cabinet',
        component: lazy(() => import('@/pages/TrainerCabinet/Dashboard')),
        exact: true,
    },
    {
        name: 'trainer-thread',
        path: '/trainer-thread/:folderName/:id?',
        component: lazy(() => import('@/pages/TrainerThread')),
        exact: true,
    },
    {
        name: 'create-order',
        path: '/place-order',
        component: lazy(() => import('@/pages/ModifyOrder')),
        exact: true,
    },
    {
        name: 'modify-order',
        path: '/edit-order/:id',
        component: lazy(() => import('@/pages/ModifyOrder')),
        exact: true,
    },
    {
        name: 'add-mdf-request',
        path: '/add-mdf-request',
        component: lazy(() => import('@/pages/AddMdfRequest')),
        exact: true,
    },
    {
        name: 'modify-mdf-request',
        path: '/edit-mdf-request/:id',
        component: lazy(() => import('@/pages/ModifyMdfRequest')),
        exact: true,
    },
    {
        name: 'laravel-root-path',
        path: '/en',
        component: () => <Redirect to="/dashboard" />,
        exact: true,
    },
    {
        name: '404',
        path: '*',
        component: lazy(() => import('@/pages/404')),
    },
]

export const getRouteConfig = (params = {}) => {
    const keys = Object.keys(params)

    if (!keys.length) {
        throw new Error('called getRouteConfig without params')
    }

    const reversedPrivateRoutes = privateRoutes.slice().reverse()

    const config = reversedPrivateRoutes.find((routeConfig) => {
        return keys.every((key) => {
            if (key === 'path' && routeConfig[key].includes(':')) {
                const routeConfigSegments = routeConfig[key].split('/')
                const paramSegments = params[key].split('/')

                if (routeConfigSegments.length === paramSegments.length) {
                    const modifiedPath = routeConfigSegments
                        .map((segment, index) => (segment.startsWith(':') ? paramSegments[index] : segment))
                        .join('/')
                    return modifiedPath === params[key]
                }
                if (key === 'path' && routeConfig[key].endsWith('?')) {
                    const pathWithoutOptionalParam = routeConfig[key].split('/').slice(0, -1).join('/')
                    return pathWithoutOptionalParam === params[key]
                }
            }
            return routeConfig[key] === params[key]
        })
    })

    if (!config) {
        throw new Error('getRouteConfig could not find any config with specified params')
    }

    return config
}
