import React, { useState, useEffect } from 'react'
import { Layout as Container, Tooltip, Button, Space, notification } from 'antd'
import Icon from '@/components/Icon'
import styled from 'styled-components'
import UserMenu from '@/components/UserMenu'
import 'flag-icon-css/sass/flag-icon.scss'
import { useDispatch, useSelector } from 'react-redux'
import useSocket from '@/hooks/useSocket'
import AworkLogo from '@/theme/img/awork_logo.svg'
import Link from '@/components/Link'
import { impersonationStop } from '@/features/user/userSlice'
import Api, { impersonate } from '@/Api'
import { useHistory , NavLink } from 'react-router-dom'
import SidebarMenu from './SidebarMenu'
import NotificationBar from './NotificationBar'

const { Header, Sider, Content } = Container

const ToggleButton = styled.button`
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-size: 17px;
    position: relative;
    transition: opacity 0.3s linear;
    &:hover {
        opacity: 0.85;
    }

    margin-left: ${(props) => (props.isCountry ? '20px' : '0')};
    &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 30%;
        top: 35%;
        left: -10px;
        background: #f0f0f0;
        display: ${(props) => (props.isCountry ? 'block' : 'none')};
    }

    svg {
        transform: translateY(1px);
    }
`

const Logo = styled.img`
    height: 64px;
    transform: translateX(-11px);
    transition: opacity 0.2s;

    &:hover {
        opacity: 0.8;
    }
`
const Wrapper = styled.div`
    display: flex;
    .menu {
        flex: 0 0 250px;
        max-width: 250px;
        min-width: 250px;
        width: 250px;
        min-height: 100vh;
        padding-left: 20px;
        padding-top: 80px;
        background-color: #9a989a;
        h1 {
            font-size: 25px;
            font-weight: bold;
        }
        ul {
            padding-left: 15px;
            li {
                a {
                    color: black;
                    font-size: 14px;
                    &.active {
                        font-weight: bold;
                    }
                }
            }
        }
    }
`

const Layout = (props) => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(null)
    const [loading, setLoading] = useState(false)
    const { role, impersonating } = useSelector((state) => state.user)
    const socket = useSocket()
    const isRoleCountry = role === 'country'
    const isRoleRoot = role === 'root'
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const toggleStatus = localStorage.getItem('toggle-notifications') === 'false'
        if (toggleStatus) {
            setSidebarCollapsed(toggleStatus)
        }
    }, [sidebarCollapsed])

    const stopImpersonation = async () => {
        setLoading(true)
        try {
            const response = await Api.request(impersonate.stop)
            if (!response.data.success) {
                throw new Error()
            }
            dispatch(impersonationStop())
            setLoading(false)
            notification.success({ message: 'Success', description: 'Impersonation stopped' })
            history.push('/')
        } catch {
            notification.success({ message: 'Success', description: 'Failed to stop impersonation' })
            setLoading(false)
        }
    }

    const toggleSidebar = () => {
        setSidebarCollapsed((prevState) => {
            localStorage.setItem('toggle-notifications', prevState || false)
            return !prevState
        })
    }

    let containerClass = 'main-layout-container '
    containerClass += `main-layout-sider-${role === 'root' ? 'left' : 'right'} `
    containerClass += sidebarCollapsed ? `main-layout-sider-${role === 'root' ? 'left' : 'right'}-collapsed` : ''

    return (
        <Container className={containerClass} hasSider>
            {isRoleRoot && (
                <Sider className="ant-layout-sider-fixed" trigger={null} collapsible collapsed={sidebarCollapsed}>
                    <div className="ant-layout-sider-header">
                        <span>sPoint</span>
                        <span>v3.0.0</span>
                    </div>
                    <SidebarMenu />
                </Sider>
            )}
            <Container>
                <Header className="ant-layout-header-fixed">
                    {isRoleCountry ? (
                        <Link to="/">
                            <Logo src={AworkLogo} alt="Awork" />
                        </Link>
                    ) : (
                        <ToggleButton type="button" onClick={toggleSidebar}>
                            <Tooltip
                                title={`${sidebarCollapsed ? 'Maximize' : 'Minimize'} Sidebar Menu`}
                                placement="bottomLeft"
                                mouseEnterDelay={0.5}
                                mouseLeaveDelay={0}
                            >
                                <Icon icon="bars" />
                            </Tooltip>
                        </ToggleButton>
                    )}
                    {impersonating ? (
                        <Space size={16} style={{ marginLeft: 'auto' }}>
                            <span>Impersonation mode</span>
                            <Button
                                danger
                                icon={<Icon icon="user-slash" btn />}
                                onClick={stopImpersonation}
                                disabled={loading}
                            >
                                Stop Impersonation
                            </Button>
                        </Space>
                    ) : null}

                    <UserMenu socket={socket} />
                    {isRoleCountry && (
                        <ToggleButton type="button" onClick={toggleSidebar} isCountry>
                            <Tooltip
                                title={`${sidebarCollapsed ? 'Show' : 'Hide'} Notification Bar`}
                                placement="bottomRight"
                                mouseEnterDelay={0.5}
                                mouseLeaveDelay={0}
                            >
                                <Icon icon={sidebarCollapsed ? 'compress' : 'expand'} />
                            </Tooltip>
                        </ToggleButton>
                    )}
                </Header>
                <Wrapper>
                    <div className='menu'>
                        <h1>Menu</h1>
                        <ul>
                            <li>
                                <NavLink to="/dashboard" exact>Dashboard</NavLink>
                            </li>
                            <li>
                                <NavLink to="/file-storage">File Storage</NavLink>
                            </li>
                            <li>
                                <NavLink to="/dashboard/trainings">Trainings</NavLink>
                                <ul>
                                    <li>
                                        <NavLink to="/t/courses">Courses</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/dashboard/crm">CRM</NavLink>
                                        <ul>
                                            <li>
                                                <NavLink to="/t/doctors">Doctors</NavLink>
                                                
                                            </li>
                                            <li>
                                                <NavLink to="/t/crm-trainers">Trainers</NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink to="/training-bonus">Training Bonus</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to="/dashboard/mdf" exact>MDF</NavLink>
                                <ul>
                                    <li>
                                        <NavLink to="/mdf-sales-plan">Sales Plan</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/mdf-actual-sales">Actual Sales</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/dashboard/mdf-sales-plan">Bonus Recommendations</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/t/mdf-requests">MDF Requests</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/dashboard/mdf-material">MDF Materials</NavLink>
                                        <ul>
                                            <li>
                                                <NavLink to="/t/mdf-categories">Categories</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/t/mdf-materials">Materials</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/t/mdf-services">Services</NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink to="/t/mdf-balances">MDF Reports</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to="/dashboard/my-company">My Company</NavLink>
                                <ul>
                                    <li>
                                        <NavLink to="/t/employees">Employees</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/t/roles">Roles</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/edit/profile">Profile</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/t/impersonation">Inpersonate</NavLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <Content>{props.children}</Content>
                </Wrapper>
                
            </Container>
            {isRoleCountry && (
                <Sider
                    className="ant-layout-sider-fixed"
                    trigger={null}
                    collapsible
                    collapsed={sidebarCollapsed}
                    collapsedWidth={0}
                >
                    <div className="ant-layout-sider-header">Notifications</div>
                    <NotificationBar socket={socket} />
                </Sider>
            )}
        </Container>
    )
}

export default Layout
