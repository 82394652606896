/*
    * since there are more than thousand icons in fontawesome library
    * and we are only going to use 200 at best,
    * we should import only those icons we use

    ? both Solid and Regular icon exports start with "fa"
    ? so rename Regular icons inside import. e.g. { faIcon as farIcon }

    ? docs: https://fontawesome.com/how-to-use/on-the-web/using-with/react#faqs
    ? icons: https://fontawesome.com/icons?d=gallery&s=regular,solid&m=free
*/
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faCaretSquareLeft as farCaretSquareLeft,
    faCaretSquareRight as farCaretSquareRight,
    faUser as farUser,
    faCopy as farCopy,
    faSave as farSave,
    faCheckCircle as farCheckCircle,
    faEye as farEye,
    faQuestionCircle as farQuestionCircle,
    faPauseCircle as farPauseCircle,
    faPlayCircle as farPlayCircle,
    faTimesCircle as farTimesCircle,
    faStar as farStar,
    faClock as farClock,
} from '@fortawesome/free-regular-svg-icons'
import {
    faEnvelope,
    faKey,
    faHome,
    faLaptopCode,
    faTable,
    faCheck,
    faTimes,
    faPlus,
    faMinus,
    faFileExport,
    faExclamationCircle,
    faPencilAlt,
    faTrashAlt,
    faInfoCircle,
    faCopy,
    faSave,
    faAngleLeft,
    faCogs,
    faEdit,
    faBoxOpen,
    faFileDownload,
    faArchive,
    faHourglassHalf,
    faEllipsisH,
    faEllipsisV,
    faList,
    faListOl,
    faListAlt,
    faUserCircle,
    faPhone,
    faPhoneAlt,
    faPhoneSquare,
    faPhoneSquareAlt,
    faCertificate,
    faGraduationCap,
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
    faUpload,
    faShoppingBag,
    faMap,
    faChalkboardTeacher,
    faQuestionCircle,
    faPollH,
    faPuzzlePiece,
    faBarcode,
    faUser,
    faUsers,
    faGift,
    faGifts,
    faFileAlt,
    faCommentDots,
    faFileArchive,
    faBuilding,
    faEnvelopeOpenText,
    faSlidersH,
    faFileContract,
    faSign,
    faUserPlus,
    faLightbulb,
    faAddressCard,
    faBullhorn,
    faCog,
    faSpinner,
    faHandshake,
    faFilePowerpoint,
    faImage,
    faFolder,
    faFolderOpen,
    faNewspaper,
    faFlask,
    faFlag,
    faArrowRight,
    faArrowLeft,
    faFilePdf,
    faFileWord,
    faFileExcel,
    faFileImage,
    faFile,
    faFolderPlus,
    faFileUpload,
    faUserMd,
    faShoppingCart,
    faChartPie,
    faChartBar,
    faVideo,
    faCalendarCheck,
    faEuroSign,
    faStar,
    faLock,
    faUserTag,
    faReceipt,
    faBoxes,
    faArrowCircleUp,
    faArrowCircleDown,
    faPeopleArrows,
    faUserSlash,
    faBookmark,
    faAddressBook,
    faMapMarkerAlt,
    faExpand,
    faCompress,
    faBars,
    faExternalLinkAlt,
    faCubes,
} from '@fortawesome/free-solid-svg-icons'

const createIconLibrary = () => {
    library.add(
        faHome,
        faEnvelope,
        faKey,
        faLaptopCode,
        farCaretSquareLeft,
        farCaretSquareRight,
        farUser,
        faTable,
        faCheck,
        faTimes,
        faPlus,
        faMinus,
        faFileExport,
        faExclamationCircle,
        faPencilAlt,
        faTrashAlt,
        faInfoCircle,
        faCopy,
        farCopy,
        faSave,
        farSave,
        faAngleLeft,
        farCheckCircle,
        faCogs,
        farEye,
        faEdit,
        faBoxOpen,
        faFileDownload,
        faArchive,
        faHourglassHalf,
        faEllipsisH,
        faEllipsisV,
        faList,
        faListOl,
        faListAlt,
        faUserCircle,
        faPhone,
        faPhoneAlt,
        faPhoneSquare,
        faPhoneSquareAlt,
        faCertificate,
        faGraduationCap,
        faGlobeAfrica,
        faGlobeAmericas,
        faGlobeAsia,
        faGlobeEurope,
        faUpload,
        faShoppingBag,
        faMap,
        faChalkboardTeacher,
        faQuestionCircle,
        faPollH,
        faPuzzlePiece,
        faBarcode,
        faUser,
        faUsers,
        faGift,
        faGifts,
        faFileAlt,
        faCommentDots,
        faFileArchive,
        faBuilding,
        faEnvelopeOpenText,
        faSlidersH,
        faFileContract,
        faSign,
        faUserPlus,
        faLightbulb,
        faAddressCard,
        faBullhorn,
        faCog,
        faSpinner,
        faHandshake,
        faFilePowerpoint,
        faImage,
        farQuestionCircle,
        faFolder,
        faFolderOpen,
        faNewspaper,
        faFlask,
        faFlag,
        faArrowRight,
        faArrowLeft,
        faFilePdf,
        faFileWord,
        faFileExcel,
        faFileImage,
        faFile,
        faFolderPlus,
        faFileUpload,
        farPauseCircle,
        farPlayCircle,
        farTimesCircle,
        faUserMd,
        faShoppingCart,
        faChartPie,
        faChartBar,
        faVideo,
        faCalendarCheck,
        faEuroSign,
        faStar,
        farStar,
        faLock,
        faUserTag,
        faReceipt,
        faBoxes,
        faArrowCircleUp,
        faArrowCircleDown,
        faPeopleArrows,
        faUserSlash,
        faBookmark,
        farClock,
        faAddressBook,
        faMapMarkerAlt,
        faExpand,
        faCompress,
        faBars,
        faExternalLinkAlt,
        faCubes
    )
}

export default createIconLibrary
