import mdf from './mdf'
import trainings from './trainings'
import sales from './sales'
import myCompany from './myCompany'

const dashboardConfig = [
    {
        key: 'File_Storage',
        label: 'File Storage',
        icon: 'folder-open',
        route: { name: 'file-storage' },
        permissions: ['view threads', 'threads'],
    },
    {
        key: 'trainings',
        label: 'Trainings',
        icon: 'address-card',
        route: { params: { page: 'trainings' }, name: 'dashboard' },
        permissions: ['view', 'trainings'],
        children: trainings,
    },
    {
        key: 'sales',
        label: 'Sales',
        icon: 'bullhorn',
        route: { params: { page: 'sales' }, name: 'dashboard' },
        permissions: ['view', 'sales'],
        children: sales,
    },
    {
        key: 'my-company',
        label: 'My Company',
        icon: 'cog',
        route: { params: { page: 'my-company' }, name: 'dashboard' },
        permissions: ['role_id', 4, false],
        children: myCompany,
    },
]

if (process.env.REACT_APP_BACKEND_URL !== 'https://api.aptos.world') {
    dashboardConfig.splice(1, 0, mdf)
}

export default dashboardConfig
