/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, current } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import moment from 'moment'
import { createTransform } from 'redux-persist'
import { updatePermissions } from '@/ability'

const defaultPermissions = [{ action: 'manage', subject: 'all' }]

const getAvatar = ({ media = [], thumb = '' }) => {
    if (media.length) {
        const logo = media.find((m) => m.collection_name === 'company_logo') || {}
        const cover = media.find((m) => m.collection_name === 'cover') || {}
        return logo.full_url || cover.full_url || thumb
    }
    return thumb
}

const getExpireTimestamp = () => {
    return moment().add(120, 'minutes').format('YYYY-MMM-DD HH:mm:ss')
}

const generateRolePermissions = (payload = {}) => {
    return [
        {
            action: 'role_id',
            subject: 'role_id',
            inverted: false,
            conditions: { role_id: Number(payload.role_id || 0) },
        },
        {
            action: 'role',
            subject: 'role',
            inverted: !payload.is_manager,
            conditions: { role: 'manager' },
        },
        {
            action: 'role',
            subject: 'role',
            inverted: !payload.is_coach,
            conditions: { role: { $eq: 'coach' } },
        },
        {
            action: 'role',
            subject: 'role',
            inverted: payload.role === 'root',
            conditions: { role: { $eq: 'country' } },
        },
        {
            action: 'role',
            subject: 'role',
            inverted: payload.role !== 'root',
            conditions: { role: { $eq: 'root' } },
        },
    ]
}

const initialState = {
    authStatus: false,
    id: null,
    name: '',
    flag: '',
    avatar: '',
    location: {},
    role_id: null,
    role: '',
    permissions: [],
    rolePermissions: [],
    is_manager: false,
    is_coach: false,
    expiresAt: null,
    remember: false,
    originalState: {},
    impersonating: false,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData: (state, { payload }) => {
            state.authStatus = true
            state.id = payload.id
            state.name = payload.role === 'country' ? payload.display_name : payload.name
            state.flag = payload.flag
            state.avatar = getAvatar(payload)
            state.location = payload.location || {}
            state.role_id = payload.role_id || null
            state.role = payload.role === 'root' ? 'root' : 'country'
            state.permissions = payload.permissions || defaultPermissions
            state.rolePermissions = generateRolePermissions(payload)
            state.is_manager = payload.is_manager
            state.is_coach = payload.is_coach

            updatePermissions(state)
        },
        logout: () => {
            storage.removeItem('persist:user')
            updatePermissions({ permissions: [], rolePermissions: [] })
            return initialState
        },
        setAuthSession: (state, { payload }) => {
            state.expiresAt = getExpireTimestamp()
            state.remember = payload.remember || false
        },
        impersonationStart: (state, { payload }) => {
            state.originalState = {
                authStatus: state.authStatus,
                id: state.id,
                name: state.name,
                flag: state.flag,
                avatar: state.avatar,
                location: state.location,
                role_id: state.role_id,
                role: state.role,
                permissions: state.permissions,
                rolePermissions: state.rolePermissions,
                is_manager: state.is_manager,
                is_coach: state.is_coach,
            }

            state.id = payload.data.id
            state.name = payload.data.display_name
            state.flag = payload.data.flag
            state.avatar = getAvatar(payload.data)
            state.location = payload.data.location || {}
            state.role_id = payload.data.role_id || null
            state.role = 'country'
            state.permissions = payload.data.permissions || defaultPermissions
            state.rolePermissions = generateRolePermissions({ ...payload, role: 'country' })
            state.is_manager = payload.is_manager
            state.is_coach = payload.is_coach
            state.impersonating = payload.is_impersonate

            updatePermissions(current(state))
        },
        impersonationStop: (state) => {
            state.impersonating = false
            state.authStatus = state.originalState.authStatus
            state.id = state.originalState.id
            state.name = state.originalState.name
            state.flag = state.originalState.flag
            state.avatar = state.originalState.avatar
            state.location = state.originalState.location
            state.role_id = state.originalState.role_id
            state.role = state.originalState.role
            state.permissions = state.originalState.permissions
            state.rolePermissions = state.originalState.rolePermissions
            state.is_manager = state.originalState.is_manager
            state.is_coach = state.originalState.is_coach
            state.originalState = {}

            updatePermissions(current(state))
        },
    },
})

export const authTokenExpired = ({ remember, expiresAt }) => {
    return !remember && moment().isSameOrAfter(expiresAt)
}

export const UserPersistTransform = createTransform(
    (inboundState) => inboundState,
    (outBoundState) => (authTokenExpired(outBoundState) ? initialState : outBoundState),
    { whitelist: ['user'] }
)

export const { setUserData, logout, setAuthSession, rehydratePermissions, impersonationStart, impersonationStop } =
    userSlice.actions
export const selectAuthStatus = (state) => state.user.authStatus
export default userSlice.reducer
