import React, { useState } from 'react'
import { Popover, Typography, Badge, Empty } from 'antd'
import BellIcon from '@/theme/img/bell.svg'
import styled from 'styled-components'
import useAlerts from '@/hooks/useAlerts'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
    margin-right: 24px;
`

const Icon = styled.img`
    transition: transform 0.2s, opacity 0.2s;
    &:hover {
        transform: scale(1.04);
        opacity: 0.88;
    }
`

const Container = styled.div`
    width: 360px;

    & > h4 {
        text-align: center;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 310px;
    overflow-y: auto;
    border-top: 1px solid #f5f5f5;
    border-radius: 14px;
`

const EmptyContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 85%;
`

const AlertItem = styled.div`
    padding: 15px 30px;
    background-color: ${(props) => (props['data-seen'] ? '#f7f7f7' : '#f5f5f5')};
    border-left: 3px solid;
    border-color: ${(props) => (props['data-seen'] ? '#eee' : '#75bee9')};
    border-bottom: 1px solid #f5f5f5;
    border-radius: 5px;
    margin-bottom: 1px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #f1f1f1;
        cursor: pointer;
    }
`

const AlertBar = ({ socket }) => {
    const [visible, setVisible] = useState(false)
    const [alerts, readAlert] = useAlerts(socket)
    const isCoach = useSelector((state) => state.user.is_coach)

    const badgeCount = alerts.filter((alert) => !alert.seen).length

    const content = (
        <Container>
            <Typography.Title level={4}>Alerts</Typography.Title>
            {alerts.length ? (
                <Content>
                    {alerts.map((alert) => (
                        <AlertItem key={alert.id} onClick={() => readAlert(alert.id)} data-seen={alert.seen}>
                            <Typography.Title level={5}>{alert.title}</Typography.Title>
                            <Typography.Text type="secondary">{alert.description}</Typography.Text>
                        </AlertItem>
                    ))}
                </Content>
            ) : (
                <Content>
                    <EmptyContent>
                        <Empty imageStyle={{ height: 70 }} description="No Alerts" />
                    </EmptyContent>
                </Content>
            )}
        </Container>
    )

    if (isCoach) {
        return null
    }

    return (
        <Wrapper>
            <Popover
                placement="bottom"
                trigger="click"
                visible={visible}
                onVisibleChange={setVisible}
                content={content}
            >
                <Badge count={badgeCount} size="small">
                    <Icon src={BellIcon} alt="" width={24} />
                </Badge>
            </Popover>
        </Wrapper>
    )
}

export default AlertBar
