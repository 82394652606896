import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import SuspenseFallback from '@/components/SuspenseFallback'
import AuthLayout from '@/pages/Auth/AuthLayout'
import Layout from '@/components/Layout'
import PrivateRoute from './PrivateRoute'
import { publicRoutes, privateRoutes } from './routerConfig'

const publicRoutePaths = publicRoutes.map((route) => route.path)

const Router = () => (
    <BrowserRouter>
        <Suspense fallback={<SuspenseFallback />}>
            <Switch>
                <Route exact path={publicRoutePaths}>
                    <AuthLayout>
                        <Switch>
                            {publicRoutes.map(({ name, ...route }) => (
                                <Route key={name} {...route} />
                            ))}
                        </Switch>
                    </AuthLayout>
                </Route>
                <PrivateRoute exact path={['/', '/*']}>
                    <Layout>
                        <Suspense fallback={<SuspenseFallback fullScreen={false} />}>
                            <Switch>
                                {privateRoutes.map(({ name, ...route }) => (
                                    <PrivateRoute key={name} {...route} />
                                ))}
                            </Switch>
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            </Switch>
        </Suspense>
    </BrowserRouter>
)

export default Router
